





























































import rootStore from "@/store";
import { DementiaState } from "@/types/dementia";
const state = rootStore.state as { dementia: DementiaState };
export default {
  name: "Profile",
  head: {
    title: {
      inner: "認知症かんたんチェック"
    }
  },
  data: function() {
    return {
      ages: [
        "50歳未満",
        "50~59歳",
        "60~64歳",
        "65~69歳",
        "70~74歳",
        "75~79歳",
        "80歳以上"
      ],
      genders: ["女性", "男性", "その他", "回答しない"]
    };
  },
  computed: {
    isAnswerComplete(): boolean {
      return ![this.checkedAge, this.checkedGender].some(
        el => el === undefined
      );
    },
    checkedAge(): undefined | string {
      return state.dementia.age;
    },
    checkedGender(): undefined | string {
      return state.dementia.gender;
    }
  },
  methods: {
    changeAgeValue(value: string): void {
      rootStore.commit("dementia/changeAgeAnswerValue", {
        value: value
      });
    },
    changeGenderValue(value: string): void {
      rootStore.commit("dementia/changeGenderAnswerValue", {
        value: value
      });
    }
  }
};
